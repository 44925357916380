import React, { useState } from "react";
import { symbolImageLink } from "../symbols/symbolImageLink";
import { getRowsForGame } from "../rows/rows";
import "./AsymmetricGridRenderer.css";

function AsymmetricGridRendererNotAvalanche(stage) {
  const { payouts, reel_window, multipliers } = stage.stage;
  let multiplierIndex = 0;

  const renderSymbol = (symbol) => {
    if (symbol === 12) {
      if (multipliers.length === 0) {
        return <span className="winNumber red-outline">WILD</span>;
      }

      const multiplier = multipliers[multiplierIndex];
      const displayText = multiplier === 1 ? "WILD" : `X${multiplier}`;
      const style = multiplier === 1 ? { fontSize: 34 } : null;

      if (multiplierIndex < multipliers.length - 1) {
        multiplierIndex++;
      }

      return (
        <span style={style} className="winNumber red-outline">
          {displayText}
        </span>
      );
    }
    return null;
  };

  const tableRows = (
    <div className="table-div">
      <div className="columns-container">
        {reel_window?.map((column, columnKey) => {
          return (
            <div className="column-container" key={columnKey}>
              {getRowsForGame(stage.gameName)?.map((row, rowKey) => {
                const symbol = column[row];
                const symbolIndex = column.indexOf(symbol);
                const reversedSymbolIndex = column.length - 1 - symbolIndex;

                const isSymbolInWinLine = payouts.values?.some((payout) => {
                  const subIndexes = payout.indexes?.[columnKey];
                  return (
                    Array.isArray(subIndexes) &&
                    subIndexes.includes(reversedSymbolIndex)
                  );
                });

                const size =
                  column.length >= 2 && column.length <= 6
                    ? column.length - 1
                    : 6;
                const brightness = isSymbolInWinLine ? 1 : 0.4;

                return (
                  <div
                    className={`${reversedSymbolIndex} symbol-container symbol-size-${size}`}
                    key={`${rowKey}-${columnKey}`}
                    style={{
                      filter: `brightness(${brightness})`,
                      display: "flex",
                      justifyContent: multipliers
                        ? multipliers[multiplierIndex] === 1
                          ? "flex-start"
                          : "center"
                        : null,
                      alignItems: "center",
                      backgroundImage: symbol
                        ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                            stage.gameName
                          )}/${String(symbol)?.toLowerCase()}_${size}.png)`
                        : "none",
                    }}
                  >
                    {multipliers ? renderSymbol(symbol) : null}
                    {!symbolImageLink(stage.gameName) &&
                      (symbol ? String(symbol).toUpperCase() : "")}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );

  return tableRows;
}

export default AsymmetricGridRendererNotAvalanche;
