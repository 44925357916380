import React, { forwardRef } from "react";
import { Carousel } from "react-bootstrap";
import { processWinlinesTable } from "../tableCalculations/processWinlinesTable";
import AsymmetricGridRendererAvalanche from "../../helpers/renderers/AsymmetricGridRendererAvalanche";

const WinlinesCarousel = forwardRef(({ stage, gameName }, ref) => {
  const renderWinResultsTable = (activeKey) => {
    const activePayout = stage.payouts?.values[activeKey];
    return (
      <table
        className="game-stats-info__stats-class mt-0"
        id="win_results_table"
      >
        <thead>
          <tr className="text-center">
            <td colSpan={3} className="py-3">
              Winning result
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <th>Payline</th>
            <th>Award</th>
            <th>Symbol</th>
          </tr>
          {activePayout && (
            <tr className="text-center">
              <td>
                {activePayout.payline
                  ? activePayout.payline + 1
                  : activeKey + 1}
              </td>
              <td>
                {stage.payouts.values[0].reel_multiplier
                  ? (activePayout.amount *
                      stage.payouts.values[0].reel_multiplier) /
                    1000
                  : activePayout.amount / 1000}
              </td>
              <td>{activePayout.symbol}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  if (gameName === "viking-saga-flexiways") {
    return (
      <Carousel interval={null} controls={stage.payouts?.values?.length > 1}>
        {stage.payouts?.values?.map((value, key) => (
          <Carousel.Item key={key}>
            <table
              className={`game-stats-info__stats-class mt-0`}
              id="symbols_table"
            >
              <thead>
                <tr className="text-center">
                  {(stage?.reel_window?.length > 0 || stage?.payouts !== 0) && (
                    <td colSpan={stage?.reel_window?.length} className="py-3">
                      Winline №{value.payline ? value.payline + 1 : key + 1}
                    </td>
                  )}
                </tr>
              </thead>
            </table>
            <AsymmetricGridRendererAvalanche
              stage={stage}
              gameName={gameName}
              winlineIndex={key}
            />
            {renderWinResultsTable(key)}
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }

  return (
    <Carousel interval={null} controls={stage.payouts?.values?.length > 1}>
      {stage.payouts?.values?.map((value, key) => (
        <Carousel.Item key={key}>
          <table
            className={`game-stats-info__stats-class mt-0`}
            id="symbols_table"
          >
            <thead>
              <tr className="text-center">
                {(stage?.reel_window?.length > 0 || stage?.payouts !== 0) && (
                  <td colSpan={stage?.reel_window?.length} className="py-3">
                    Winline №{value.payline ? value.payline + 1 : key + 1}
                  </td>
                )}
              </tr>
            </thead>
            {processWinlinesTable(stage, value, gameName)}
          </table>
          {renderWinResultsTable(key)}
        </Carousel.Item>
      ))}
    </Carousel>
  );
});

export default WinlinesCarousel;
