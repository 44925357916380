import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Row, Card, Col, Table } from "react-bootstrap";
import { getCurrenciesExchangeRate } from "../../../services";
import { useMutation, useQuery, useQueryClient } from "react-query";

const currenciesType = ["main", "common", "crypto", "fake", "other"];

export const ExchangeRateModal = ({ setShowExchangeRateModal, allias }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data, isLoading } = useQuery(
    [allias, { limit, page }], // Передаем alias первым аргументом и объект параметров вторым
    getCurrenciesExchangeRate,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  return (
    <>
      <Modal
        show
        onHide={() => setShowExchangeRateModal(false)}
        centered={true}
        size="lg"
      >
        <Modal.Header>
          <h6 className="modal-title">Create currency</h6>
          <Button
            variant=""
            type="button"
            onClick={() => setShowExchangeRateModal(false)}
          >
            <span aria-hidden="true" className="text-dark">
              X
            </span>
          </Button>
        </Modal.Header>
        <Modal.Body style={{ width: "100%" }}>
          <Table striped="columns">
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>Created at</th>
                <th>From currency</th>
                <th>To currency</th>
                <th>Rate</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {data
                ?.slice()
                .reverse()
                .map((item, index) => (
                  <tr key={index}>
                    <td>{item.created_at}</td>
                    <td>{item.from}</td>
                    <td>{item.to}</td>
                    <td>{item.rate}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};
//  <Spinner
//             animation="border"
//             variant="primary"
//             className="spinner-border me-2 text-light"
//             role="status"
//           >
//             <span className="sr-only">Loading...</span>
//           </Spinner>
