import React, { useEffect, useState } from "react";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Select } from "antd";
import { useQuery, useQueryClient } from "react-query";

import { Table } from "../../components/Tables";
import { getCurrenciesInfo, getCurrenciesExchangeRate } from "../../services";
import { CreateCurrencyModal } from "./components/CreateCurrencyModal";
import { DeleteCurrencyModal } from "./components/DeleteCurrencyModal";
import { ExchangeRateModal } from "./components/ExchangeRateModal";

const Currencies = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExchangeRateModal, setShowExchangeRateModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [allias, setAllias] = useState(null);
  const [selectedDataExchangeRate, setSelectedDataExchangeRate] =
    useState(null);
  const queryClient = useQueryClient();

  //   const alias = selectedData?.alias;

  const { data: currencies, isLoading } = useQuery(
    ["currencies"],
    getCurrenciesInfo,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  const handleClick = async (alias) => {
    if (alias) {
      setLoading(true);
      try {
        const data = await queryClient.fetchQuery(
          ["data-currencies", { limit, page }],
          () =>
            getCurrenciesExchangeRate(alias, [
              "data-currencies",
              { limit, page },
            ])
        );
        setSelectedDataExchangeRate(data);
      } finally {
        setLoading(false);
      }
    }
  };

  const columns = [
    {
      className: "text-center",
      key: "all",
      name: "Currency",
      highlight: true,
      render: (data) => (
        <div
          className="d-flex align-items-center justify-content-center"
          // onClick={() => {
          //     setSelectedData(data)
          //     setShowEditModal(true)}}
        >
          <span
            className="text-primary text-underline cursor-pointer d-block text-truncate me-2"
            style={{
              textDecoration: "underline",
              maxWidth: 140,
            }}
          >
            {data?.title?.toUpperCase()}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Alias",
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <span
            className="d-block text-truncate me-2"
            style={{
              maxWidth: 140,
            }}
          >
            {data?.alias}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Additional alias rate",
      render: (data) => (
        <span
          className="d-block text-truncate mx-auto"
          style={{ maxWidth: 140 }}
        >
          {data?.additional_alias_rate}
        </span>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Type",
      render: (data) => (
        <span
          className="d-block text-truncate mx-auto"
          style={{ maxWidth: 140 }}
        >
          {data?.type}
        </span>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Base currency",
      render: (data) => (
        <span
          className="d-block text-truncate mx-auto"
          style={{ maxWidth: 140 }}
        >
          {data?.base_currency}
        </span>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Manage",
      width: 60,
      render: (data) => (
        <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className={`btn btn-outline-info d-flex align-items-center justify-content-center p-1`}
              onClick={() => {
                setSelectedData(data);
                setAllias(data.alias);
                setShowExchangeRateModal(true);
              }}
            >
              <i className="fe fe-dollar-sign" />
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className={`btn btn-outline-danger d-flex align-items-center justify-content-center p-1`}
              onClick={() => {
                setSelectedData(data);
                setShowDeleteModal(true);
              }}
            >
              <i className="fe fe-trash" />
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      {showAddModal && (
        <CreateCurrencyModal
          setShowModal={setShowAddModal}
          currencies={currencies}
        />
      )}
      {showDeleteModal && (
        <DeleteCurrencyModal
          setShowModal={setShowDeleteModal}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
        />
      )}
      {showExchangeRateModal && (
        <ExchangeRateModal
          setShowExchangeRateModal={setShowExchangeRateModal}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          selectedDataExchangeRate={selectedDataExchangeRate}
          loadin={loading}
          allias={allias}
        />
      )}

      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Currency</span>
        </div>
      </div>

      <Row>
        <form
          className="wd-95p mg-l-20"
          id="filters"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="w-100 d-inline-block">
            <div className="row align-items-center justify-content-between">
              <div className="form-group col-2">
                <div
                  className="tx-15 mg-l-45 mg-t-30 cursor-pointer"
                  onClick={() => setShowAddModal(true)}
                >
                  <span>Create</span>
                  <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Row>

      <Row className="mb-3 mt-4 justify-content-center">
        <Col xl={11}>
          <Card>
            <Card.Body className="pt-4 example1-table">
              <Table
                loading={isLoading}
                data={currencies ? currencies : []}
                columns={columns}
                total={currencies ? currencies?.length : 0}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Currencies;
