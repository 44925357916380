import React from "react";
import { Accordion, Carousel } from "react-bootstrap";

//helpers
import { stages } from "../helpers/stages/stages";
import { symbolImageLink } from "../helpers/symbols/symbolImageLink";
import { getRowsForGame } from "../helpers/rows/rows";
import { replaceSymbols } from "../helpers/symbols/replaceSymbols";

//tables
import { GameStateTable } from "./GameStateTable";
import { GameStatisticsTable } from "./GameStatisticsTable";
import { StopsTable } from "./StopsTable";
import { GamblesTable } from "./GamblesTable";
import "../styles/table.scss";
import AsgardPartyRenderer from "../helpers/renderers/AsgardPartyRenderer";
import ExpendedWildTable from "./ExpendedWildTable";
import WinlinesCarousel from "./components/WinlinesCarousel";
import { calculateFiguresIndexes } from "./tableCalculations/calculateFiguresIndexes";
import AsymmetricGridRenderer from "../helpers/renderers/AsymmetricGridRenderer";
import CrashGameRenderer from "../helpers/renderers/CrashGameRenderer";
import { BonusMultiplayerTable } from "./BonusMultiplayerTable";
import AsymmetricGridRendererNotAvalanche from "../helpers/renderers/AsymmetricGridRendererNotAvalanche";
import WinlinesCarouselAssymetric from "./components/WinlinesCarouselAssymetric";
import AsymmetricGridRendererAvalanche from "../helpers/renderers/AsymmetricGridRendererAvalanche";

export const ResultTable = ({ gameName, data, isPublic = false }) => {
  const isSpecialGame = [
    "asgard-party",
    "ego-draconis",
    "toucan-fruits",
    "magic-moonlight",
    "cleos-riches-flexiways",
    "great-bear-multiways",
    "witch-of-fortune-multiways",
    "sweet-mystery-flexiways",
    "coral-reef-flexiways",
    "cat-mansion",
  ].includes(gameName);
  const isCrashGame = ["aviator", "sky-warzone", "plinko"].includes(gameName);
  const isCollapseControl = [
    "stones-of-magic",
    "secret-totems",
    "olympus-quest",
    "lucky-skulls-bonanza",
    "rush-moji",
    "lucky-santa-bonanza",
    "jelly-joy-bonanza",
    "irish-riches-bonanza",
    "gonzo-quest",
    "viking-saga-flexiways",
  ].includes(gameName);
  const stagesData = stages(gameName, data) || [];

  function processCrasherTable() {
    let tableRows;
    tableRows = <CrashGameRenderer data={data} />;
    return tableRows;
  }

  function processResultTable(stage, currentFigures, currentTopFigures) {
    let tableRows;
    if (
      gameName === "asgard-party" ||
      gameName === "ego-draconis" ||
      gameName === "sweet-mystery-flexiways"
    ) {
      tableRows = (
        <AsgardPartyRenderer
          stage={stage}
          currentFigures={currentFigures}
          currentTopFigures={currentTopFigures}
          gameName={gameName}
        />
      );
    } else if (
      gameName === "magic-moonlight" ||
      gameName === "cleos-riches-flexiways" ||
      gameName === "great-bear-multiways" ||
      gameName === "witch-of-fortune-multiways"
    ) {
      tableRows = (
        <AsymmetricGridRenderer
          stage={stage}
          currentFigures={currentFigures}
          currentTopFigures={currentTopFigures}
          gameName={gameName}
        />
      );
    } else if (
      gameName === "coral-reef-flexiways" ||
      gameName === "cat-mansion"
    ) {
      tableRows = (
        <AsymmetricGridRendererNotAvalanche
          stage={stage}
          currentFigures={currentFigures}
          currentTopFigures={currentTopFigures}
          gameName={gameName}
        />
      );
    } else if (gameName === "viking-saga-flexiways") {
      tableRows = (
        <AsymmetricGridRendererAvalanche
          stage={stage}
          currentFigures={currentFigures}
          currentTopFigures={currentTopFigures}
          gameName={gameName}
        />
      );
    } else {
      tableRows = getRowsForGame(gameName)?.map((row, rowKey) => (
        <tr key={rowKey}>
          {stage?.reel_window?.map((column, columnKey) => {
            const reversedColumn = [...column].reverse();
            let symbol, symbolIndex;
            let reelType = "";

            if (gameName === "rio-bay-hold-n-win") {
              reelType =
                "reel_type" in data?.spin
                  ? data.spin.reel_type === 0
                    ? ""
                    : `${data.spin.reel_type}_`
                  : "";
            }

            if (
              gameName === "toucan-fruits" ||
              gameName === "magic-moonlight" ||
              gameName === "cleos-riches-flexiways" ||
              gameName === "great-bear-multiways" ||
              gameName === "witch-of-fortune-multiways" ||
              gameName === "lucky-santa-bonanza" ||
              gameName === "lucky-skulls-bonanza" ||
              gameName === "irish-riches-bonanza" ||
              gameName === "jelly-joy-bonanza" ||
              gameName === "rush-moji"
            ) {
              symbol = reversedColumn[row];
              symbolIndex = reversedColumn.indexOf(symbol);
            } else {
              symbol = column[row];
              symbolIndex = column.indexOf(symbol);
            }

            let isSymbolInWinLine = false;

            if (stage?.bonus_game !== null && stage?.bonus_game !== undefined) {
              if (symbol === "S" || symbol === "s") {
                isSymbolInWinLine = true;
              }
              if (gameName === "jelly-joy-bonanza" && symbol === 8) {
                isSymbolInWinLine = true;
              }
            }

            if (gameName === "toucan-fruits") {
              if (
                stage?.payouts?.values?.some((payout) => {
                  const subIndexes = payout.indexes?.[columnKey];
                  return (
                    Array.isArray(subIndexes) &&
                    subIndexes?.includes(symbolIndex)
                  );
                })
              ) {
                isSymbolInWinLine = true;
              }
            }

            const brightness = isSymbolInWinLine ? 1 : 0.4;
            return (
              <td
                key={columnKey}
                style={{
                  filter: `brightness(${brightness})`,
                  backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                    gameName
                  )}/${reelType}${String(symbol)?.toLowerCase()}.png)`,
                }}
              >
                {!symbolImageLink(gameName) &&
                  (symbol ? String(symbol).toUpperCase() : "")}
              </td>
            );
          })}
        </tr>
      ));
    }
    return tableRows;
  }

  function processBonusTable(stage, currentFigures, currentTopFigures) {
    let bonusTableRows;

    if (
      gameName === "asgard-party" ||
      gameName === "ego-draconis" ||
      gameName === "sweet-mystery-flexiways" ||
      gameName === "magic-moonlight" ||
      gameName === "cleos-riches-flexiways" ||
      gameName === "great-bear-multiways" ||
      gameName === "witch-of-fortune-multiways" ||
      gameName === "coral-reef-flexiways" ||
      gameName === "cat-mansion" ||
      gameName === "viking-saga-flexiways"
    ) {
      return processResultTable(stage, currentFigures, currentTopFigures);
    }
    if (stage.payouts?.scatter_values?.length > 0) {
      replaceSymbols([...stage.reel_window], stage.payouts.scatter_values);
      bonusTableRows = getRowsForGame(gameName)?.map((row, rowKey) => (
        <tr key={rowKey}>
          {stage?.reel_window?.map((column, columnKey) => {
            const symbol = column[row];
            const symbolIndex = columnKey + rowKey * stage?.reel_window.length;
            let isSymbolInWinLine = false;

            const highlightIndexes = {};
            stage?.payouts?.scatter_values.forEach((scatter) => {
              if (scatter.indexes?.length > 0) {
                scatter.indexes.forEach((index) => {
                  highlightIndexes[index] = scatter.symbol;
                });
              } else {
                if (stage?.reels?.scatter_position !== undefined) {
                  stage.reels.scatter_position.forEach((position) => {
                    const { reel_id } = position;

                    if (columnKey === reel_id) {
                      highlightIndexes[symbolIndex] = scatter.symbol;
                    }
                  });
                }
              }
            });

            if (highlightIndexes[symbolIndex]) {
              isSymbolInWinLine = true;
            }

            const brightness = isSymbolInWinLine ? 1 : 0.4;

            return (
              <td
                key={columnKey}
                style={{
                  filter: `brightness(${brightness})`,
                  backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                    gameName
                  )}/${String(symbol).toLowerCase()}.png)`,
                }}
              >
                {!symbolImageLink(gameName) &&
                  (symbol ? String(symbol).toUpperCase() : "")}
              </td>
            );
          })}
        </tr>
      ));
    } else {
      bonusTableRows = getRowsForGame(gameName)?.map((row, rowKey) => (
        <tr key={rowKey}>
          {stage?.reel_window?.map((column, columnKey) => {
            const reversedColumn = [...column].reverse();
            let symbol, symbolIndex;
            if (
              gameName === "toucan-fruits" ||
              gameName === "lucky-santa-bonanza" ||
              gameName === "jelly-joy-bonanza" ||
              gameName === "lucky-skulls-bonanza" ||
              gameName === "rush-moji"
            ) {
              symbol = reversedColumn[row];
              symbolIndex = reversedColumn.indexOf(symbol);
            } else {
              symbol = column[row];
              symbolIndex = column.indexOf(symbol);
            }
            let isSymbolInWinLine = false;

            if (gameName === "toucan-fruits") {
              if (
                stage?.payouts?.values?.some((payout) => {
                  const subIndexes = payout.indexes?.[columnKey];
                  return (
                    Array.isArray(subIndexes) &&
                    subIndexes?.includes(symbolIndex)
                  );
                })
              ) {
                isSymbolInWinLine = true;
              }
            }

            const highlightIndexes = {};
            stage?.payouts?.scatter_values?.forEach((scatter) => {
              if (scatter.indexes?.length > 0) {
                scatter.indexes.forEach((index) => {
                  highlightIndexes[index] = scatter.symbol;
                });
              } else {
                if (stage?.reels?.scatter_position !== undefined) {
                  stage.reels.scatter_position.forEach((position) => {
                    const { reel_id } = position;

                    if (columnKey === reel_id) {
                      highlightIndexes[symbolIndex] = scatter.symbol;
                    }
                  });
                }
              }
            });

            if (highlightIndexes[symbolIndex]) {
              isSymbolInWinLine = true;
            }

            const brightness = isSymbolInWinLine ? 1 : 0.4;
            return (
              <td
                key={columnKey}
                style={{
                  filter: `brightness(${brightness})`,
                  backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                    gameName
                  )}/${String(symbol)?.toLowerCase()}.png)`,
                }}
              >
                {!symbolImageLink(gameName) &&
                  (symbol ? String(symbol).toUpperCase() : "")}
              </td>
            );
          })}
        </tr>
      ));
    }
    return bonusTableRows;
  }

  const processRespinTableContent = (stage, key) => {
    let payLineIndexes = key !== null ? stage?.pay_items[key]?.indexes : null;

    const respinTableRows = getRowsForGame(gameName)?.map((row, rowKey) => (
      <tr key={rowKey}>
        {stage?.window?.map((column, columnKey) => {
          const symbol = column[row];
          let isSymbolInWinLine = false;

          if (payLineIndexes) {
            payLineIndexes.forEach((indexes, colIndex) => {
              if (colIndex === columnKey && indexes?.includes(rowKey)) {
                isSymbolInWinLine = true;
              }
            });
          }

          if (gameName === "fortune-777-respin") {
            stage?.pay_items?.forEach((payItem, payItemIndex) => {
              if (key === payItemIndex) {
                payItem?.pay_line?.forEach((payLineSymbol) => {
                  if (
                    row === Math.floor(payLineSymbol / stage.window.length) &&
                    columnKey === payLineSymbol % stage.window.length
                  ) {
                    isSymbolInWinLine = true;
                  }
                });
              }
            });
          }

          const brightness = isSymbolInWinLine ? 1 : 0.4;
          return (
            <td
              key={columnKey}
              style={{
                filter: `brightness(${brightness})`,
                backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                  gameName
                )}/${String(symbol)?.toLowerCase()}.png)`,
              }}
            >
              {!symbolImageLink(gameName) &&
                (symbol ? String(symbol).toUpperCase() : "")}
            </td>
          );
        })}
      </tr>
    ));
    return respinTableRows;
  };

  const renderTableContent = (stage, key, tableRows) => {
    const className = `${
      gameName === "asgard-party" ||
      gameName === "ego-draconis" ||
      gameName === "sweet-mystery-flexiways"
        ? "narrow-table"
        : ""
    }`;

    return (
      <table
        className={`game-stats-info__stats-class mt-0 ${className}`}
        id="symbols_table"
      >
        <thead>
          <tr className="text-center">
            {(stage?.reel_window?.length > 0 || stage?.payouts !== 0) && (
              <td
                colSpan={
                  className
                    ? 7
                    : gameName === "carnival-beauty-respin" ||
                      gameName === "fortune-777-respin"
                    ? 5
                    : stage?.reel_window?.length
                }
                className="py-3"
              >
                Table - {key + 1}
              </td>
            )}
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    );
  };

  return (
    <div className="result-table p-2 radius-10 overflow-scroll">
      <div id="main">
        {!isPublic && <GameStateTable data={data} gameName={gameName} />}
        <div className="game-stats-info">
          {!isPublic && <GameStatisticsTable data={data} gameName={gameName} />}
          {!isPublic && <StopsTable data={data} gameName={gameName} />}
          {!isPublic && <GamblesTable data={data} />}

          {!isPublic && data?.spin?.is_expended_wild && (
            <ExpendedWildTable data={data} />
          )}

          {!!stagesData.length && stagesData[0]?.bonus_multiplier_game && (
            <BonusMultiplayerTable
              data={stagesData[0]?.bonus_multiplier_game}
            />
          )}

          {!!stagesData.length && (
            <p className="tx-16 tx-bold mt-4 mb-2">Reels</p>
          )}

          <div className="Withcontrols">
            <Carousel
              interval={null}
              controls={stagesData.length > 1 && !isCollapseControl}
            >
              {!isCrashGame &&
                stagesData?.map((stage, key) => {
                  let calculatedFigures = calculateFiguresIndexes(
                    gameName,
                    data,
                    key
                  );
                  const tableRows = processResultTable(
                    stage,
                    calculatedFigures.currentFigures,
                    calculatedFigures.currentTopFigures
                  );

                  if (
                    (data.spin?.amount > 0 ||
                      data.spin?.total_win > 0 ||
                      data.spin?.base_award > 0 ||
                      data.spin?.award > 0 ||
                      data.spin?.base_win > 0) &&
                    !isSpecialGame
                  ) {
                    if (stagesData?.length > 1) {
                      return (
                        <Accordion>
                          <Accordion.Item eventKey={key}>
                            <Accordion.Header>
                              Stage - {key + 1}
                            </Accordion.Header>
                            <Accordion.Body>
                              {stage.payouts?.values?.length >= 1 ? (
                                <WinlinesCarousel
                                  stage={stage}
                                  gameName={gameName}
                                  key={key}
                                />
                              ) : (
                                renderTableContent(stage, key, tableRows)
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      );
                    } else {
                      return stage?.payouts?.values?.length >= 1 ? (
                        <WinlinesCarousel
                          stage={stage}
                          gameName={gameName}
                          key={key}
                        />
                      ) : (
                        <Carousel.Item key={key}>
                          {renderTableContent(stage, key, tableRows)}
                        </Carousel.Item>
                      );
                    }
                  } else {
                    if (
                      gameName === "coral-reef-flexiways" ||
                      gameName === "cat-mansion"
                    ) {
                      return stage?.payouts?.values?.length >= 1 ? (
                        <WinlinesCarouselAssymetric
                          stage={stage}
                          gameName={gameName}
                          key={key}
                        />
                      ) : (
                        <Carousel.Item key={key}>
                          {renderTableContent(stage, key, tableRows)}
                        </Carousel.Item>
                      );
                    } else {
                      return (
                        <Carousel.Item key={key}>
                          {renderTableContent(stage, key, tableRows)}
                        </Carousel.Item>
                      );
                    }
                  }
                })}
            </Carousel>

            {!isCrashGame &&
              stagesData?.map((stage) => {
                return stage?.bonus_game ? (
                  <>
                    <p className="tx-16 tx-bold mt-4 mb-2">Free spins reels</p>
                    <Accordion>
                      {stage?.bonus_game?.spins?.map((spin, key) => {
                        return (
                          <Accordion.Item eventKey={key} key={key}>
                            <Accordion.Header>
                              Free spin - {key + 1}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Carousel
                                interval={null}
                                controls={
                                  spin?.stages?.length > 1 &&
                                  gameName !== "olympus-quest" &&
                                  gameName !== "stones-of-magic" &&
                                  gameName !== "secret-totems" &&
                                  gameName !== "lucky-skulls-bonanza" &&
                                  gameName !== "rush-moji" &&
                                  gameName !== "lucky-santa-bonanza" &&
                                  gameName !== "jelly-joy-bonanza" &&
                                  gameName !== "irish-riches-bonanza" &&
                                  gameName !== "viking-saga-flexiways"
                                }
                              >
                                {spin?.stages?.map((stage, key) => {
                                  let currentFigures = [];
                                  let currentTopFigures = [];

                                  for (let i = 0; i <= key; i++) {
                                    const currentStage = spin?.stages?.[i];
                                    const {
                                      new_figures_position,
                                      new_reel_figures,
                                      payouts,
                                      new_top_figures,
                                    } = currentStage || {};

                                    if (
                                      new_reel_figures &&
                                      new_reel_figures.length > 0
                                    ) {
                                      new_reel_figures.forEach((newFigure) => {
                                        const duplicateFigures =
                                          currentFigures.filter(
                                            (figSet) =>
                                              figSet[0]?.x === newFigure.x &&
                                              figSet[0]?.y === newFigure.y
                                          );

                                        if (duplicateFigures.length > 0) {
                                          duplicateFigures.forEach(
                                            (duplicateSet) => {
                                              duplicateSet.push(newFigure);
                                            }
                                          );
                                        } else {
                                          currentFigures.push([newFigure]);
                                        }
                                      });
                                    }

                                    if (
                                      new_top_figures &&
                                      new_top_figures.length > 0
                                    ) {
                                      new_top_figures.forEach((newFigure) => {
                                        currentTopFigures.findIndex(
                                          (topFig) => topFig.x === newFigure.x
                                        );
                                        currentTopFigures.push(newFigure);
                                      });
                                    }

                                    if (new_figures_position) {
                                      currentFigures = currentFigures.map(
                                        (figureSet) => {
                                          if (i !== key) {
                                            return figureSet.map((figure) => {
                                              const newPosition =
                                                new_figures_position.find(
                                                  (newFigure) =>
                                                    newFigure.id === figure?.id
                                                );
                                              if (newPosition) {
                                                return {
                                                  ...figure,
                                                  y: newPosition.y,
                                                };
                                              } else {
                                                return figure;
                                              }
                                            });
                                          } else {
                                            return figureSet;
                                          }
                                        }
                                      );

                                      currentTopFigures = currentTopFigures.map(
                                        (figure) => {
                                          const newPosition =
                                            new_figures_position.find(
                                              (newTopFigure) =>
                                                newTopFigure.id === figure.id
                                            );
                                          if (newPosition) {
                                            return {
                                              ...figure,
                                              x: newPosition.x,
                                            };
                                          } else {
                                            return figure;
                                          }
                                        }
                                      );
                                    }

                                    if (i !== key) {
                                      const payoutFigureIds = [];

                                      currentFigures = currentFigures.map(
                                        (figureSet) => {
                                          return figureSet.filter(
                                            (fig) =>
                                              !fig?.is_special ||
                                              fig.name === "f" ||
                                              fig.name === "w"
                                          );
                                        }
                                      );

                                      currentTopFigures =
                                        currentTopFigures.filter(
                                          (topFig) =>
                                            !topFig.is_special ||
                                            topFig.name === "f" ||
                                            topFig.name === "w"
                                        );

                                      payouts?.values?.forEach((payout) => {
                                        if (payout.figures) {
                                          payoutFigureIds.push(
                                            ...payout.figures
                                          );
                                        }
                                      });

                                      currentFigures = currentFigures.map(
                                        (figureSet) => {
                                          return figureSet.filter(
                                            (fig) =>
                                              !payoutFigureIds.includes(
                                                fig?.id
                                              ) &&
                                              (!fig?.is_special ||
                                                fig.name === "f" ||
                                                fig.name === "w")
                                          );
                                        }
                                      );

                                      currentTopFigures =
                                        currentTopFigures.filter(
                                          (topFig) =>
                                            !payoutFigureIds.includes(
                                              topFig.id
                                            ) &&
                                            (!topFig.is_special ||
                                              topFig.name === "f" ||
                                              topFig.name === "w")
                                        );
                                    }
                                  }

                                  const bonusTableRows = processBonusTable(
                                    stage,
                                    currentFigures,
                                    currentTopFigures
                                  );
                                  if (
                                    (spin?.amount > 0 ||
                                      spin?.total_win > 0 ||
                                      spin?.base_award > 0 ||
                                      spin?.award > 0 ||
                                      spin?.base_win > 0 ||
                                      spin?.payouts_info?.spin_payouts !==
                                        null) &&
                                    !isSpecialGame
                                  ) {
                                    if (spin?.stages?.length > 1) {
                                      return (
                                        <Accordion>
                                          <Accordion.Item eventKey={key}>
                                            <Accordion.Header>
                                              Stage - {key + 1}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              {stage.payouts?.values?.length >=
                                              1 ? (
                                                <WinlinesCarousel
                                                  stage={stage}
                                                  gameName={gameName}
                                                />
                                              ) : (
                                                renderTableContent(
                                                  stage,
                                                  key,
                                                  bonusTableRows
                                                )
                                              )}
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      );
                                    } else {
                                      return stage.payouts?.values?.length >=
                                        1 ? (
                                        <WinlinesCarousel
                                          stage={stage}
                                          gameName={gameName}
                                        />
                                      ) : (
                                        <Carousel.Item key={key}>
                                          {renderTableContent(
                                            stage,
                                            key,
                                            bonusTableRows
                                          )}
                                        </Carousel.Item>
                                      );
                                    }
                                  } else {
                                    if (
                                      gameName === "coral-reef-flexiways" ||
                                      gameName === "cat-mansion"
                                    ) {
                                      return stage?.payouts?.values?.length >=
                                        1 ? (
                                        <WinlinesCarouselAssymetric
                                          stage={stage}
                                          gameName={gameName}
                                          key={key}
                                        />
                                      ) : (
                                        <Carousel.Item key={key}>
                                          {renderTableContent(
                                            stage,
                                            key,
                                            bonusTableRows
                                          )}
                                        </Carousel.Item>
                                      );
                                    } else {
                                      return (
                                        <Carousel.Item key={key}>
                                          {renderTableContent(
                                            stage,
                                            key,
                                            bonusTableRows
                                          )}
                                        </Carousel.Item>
                                      );
                                    }
                                  }
                                })}
                              </Carousel>
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  </>
                ) : (
                  ""
                );
              })}

            {!isCrashGame &&
              stagesData?.map((stage) => {
                return stage?.respin_game && stage?.respin_game?.spins[0] ? (
                  <>
                    <p className="tx-16 tx-bold mt-4 mb-2">Respin reels</p>
                    <Accordion>
                      {stage?.respin_game?.spins?.map((spin, key) => {
                        return (
                          <Accordion.Item eventKey={key} key={key}>
                            <Accordion.Header>
                              Respin - {key + 1}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Carousel
                                interval={null}
                                controls={spin?.pay_items?.length > 1}
                              >
                                {spin?.pay_items ? (
                                  spin.pay_items.map((stage, key) => {
                                    const respinTableRows =
                                      processRespinTableContent(spin, key);
                                    return (
                                      <Carousel.Item key={key}>
                                        {renderTableContent(
                                          stage,
                                          key,
                                          respinTableRows
                                        )}
                                      </Carousel.Item>
                                    );
                                  })
                                ) : (
                                  <Carousel.Item>
                                    {renderTableContent(
                                      null,
                                      null,
                                      processRespinTableContent(spin, null)
                                    )}
                                  </Carousel.Item>
                                )}
                              </Carousel>
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  </>
                ) : (
                  ""
                );
              })}

            {isCrashGame && processCrasherTable()}
          </div>
        </div>
      </div>
    </div>
  );
};
