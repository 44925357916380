import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useCurrency } from "../../contexts";
import { useQuery } from "react-query";
import { getPublicSession, getSession } from "../../services";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { convertToCurrency } from "../../utilities";
import moment from "moment";
import { GameStateCard } from "../SessionDetail/gameStateCard";

const History = () => {
  const { id } = useParams();
  const { currency } = useCurrency();
  const query = new URLSearchParams(useLocation().search);
  const game = query.get("game");

  const { data, isLoading } = useQuery(
    ["session-detail", { id }, { game }],
    getPublicSession,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const renderData = (displayData) => {
    if (isLoading) {
      return <Spinner size="sm" />;
    }
    if (data) {
      console.log(data);
      return displayData;
    }
    return " - ";
  };

  return (
    <React.Fragment>
      {/* <div className="breadcrumb-header justify-content-between">
        <div className="center-content">
          <h2 className="tx-20 px-4">
            Session Detail
          </h2>
        </div>
      </div> */}
      {/* <Card>
        <Card.Body className="p-3">
          <Row className="m-2 py-3 bd-t">
            <Col xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Game name</div>
              <div className="tx-18 text-info">
                {renderData(data?.game)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">Date (UTC)</div>
              <div className="tx-18 text-info">
                {renderData(
                  moment(data?.created_at).format("DD-MM-YYYY HH:mm UTC")
                )}
              </div>
            </Col>
          </Row>
          <Row className="m-2 py-3 bd-t">
            <Col xl={6} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">User ID</div>
              <div className="tx-18 text-info text-truncate">
                {renderData(data?.external_user_id)}
              </div>
            </Col>
            <Col className="bd-s" xl={6} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">Session ID</div>
              <div className="tx-18 text-info text-truncate">
                {renderData(data?.session_token)}
              </div>
            </Col>
          </Row>
          <Row className="m-2 py-3 bd-t">
            <Col xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Currency</div>
              <div className="tx-18 text-info">
                {renderData(data?.currency)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Start Balance</div>
              <div className="tx-18 text-info">
                {renderData(
                  convertToCurrency(data?.start_balance, data?.currency)
                )}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">End Balance</div>
              <div className="tx-18 text-info">
                {renderData(
                  convertToCurrency(data?.end_balance, data?.currency)
                )}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">Wager</div>
              <div className="tx-18 text-info">
                {renderData(convertToCurrency(data?.wager, data?.currency))}
              </div>
            </Col>
          </Row>
          <Row className="m-2 py-3 bd-t">
            <Col xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Base Award</div>
              <div className="tx-18 text-info">
                {renderData(
                  convertToCurrency(data?.base_award, data?.currency)
                )}
              </div>
            </Col>
            <Col className="bd-s" xl={6} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">Bonus Award</div>
              <div className="tx-18 text-info">
                {renderData(
                  convertToCurrency(data?.bonus_award, data?.currency)
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card> */}
      <h2 style={{ padding: "1.25rem" }} className="pb-0 tx-20 mb-0">
        Game Rounds
      </h2>
      {data?.spins?.map((spin, index) => {
        return spin ? (
          <GameStateCard data={spin} key={spin.id} isPublic={true} />
        ) : (
          ""
        );
      })}
    </React.Fragment>
  );
};

export default History;
