import { useProfile } from "../../../contexts";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  deleteOperatorPair,
  getAllGames,
  getOperatorPair,
  getOrganizationGames,
  getOrganizationPair,
  getOrganizations,
  getWagerSets,
  removeIntegratedGame,
  removeOrganizationPair,
  getOrganizationWagerSet,
  removeWagerSet,
} from "../../../services";
import { Slide, toast } from "react-toastify";
import {
  Tab,
  Card,
  Col,
  Nav,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { Table } from "../../../components/Tables";
import { Select } from "antd";
import { IntegrateModal } from "./components/integrateModal";
import { WagerSetModal } from "./components/WagerSetModal";
import { copyToClipboard } from "../../../utilities";
import { DeleteIntegrationModal } from "./components/deleteIntegrationModal";
import { AddPairModal } from "./components/addPairModal";
import { DeletePairModal } from "./components/deletePairModal";
import { EditIntegrationModal } from "./components/editIntegrationModal";
import { EditWagerSetModal } from "./components/EditWagerSetModal";

const Integrations = () => {
  const { organizationId } = useProfile();

  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [filteredWagerSet, setFilteredWagerSet] = useState([]);
  const [pair, setPair] = useState([]);
  const [pairCurrency, setPairCurrency] = useState(null);
  const [pairWagerSet, setPairWagerSet] = useState(null);
  const [operatorPair, setOperatorPair] = useState([]);
  const [integrators, setIntegrators] = useState([]);
  const [operators, setOperators] = useState([]);
  const [providers, setProviders] = useState([]);
  const [wagerSets, setWagerSets] = useState([]);
  const [organizationWagerSet, setOrganizationWagerSet] = useState([]);

  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedEditItem, setSelectedEditItem] = useState(null);
  const [selectedEditItemWagerSet, setSelectedEditItemWagerSet] =
    useState(null);

  const [integrateModal, setIntegrateModal] = useState(false);
  const [wagerSetModal, setWagerSetModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteWagerSetModal, setDeleteWagerSetModal] = useState(false);
  const [addPairModal, setAddPairModal] = useState(false);
  const [addOperatorPairModal, setAddOperatorPairModal] = useState(false);
  const [deletePairModal, setDeletePairModal] = useState(false);
  const [editIntegrationsModal, setEditIntegrationsModal] = useState(false);
  const [editWagerSetModal, setEditWagerSetModal] = useState(false);

  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);

  let { refetch: refetchOrganizationWager, isWagerSetRefetching } = useQuery(
    ["organizationWagerSetData", { selectedOrganization }],
    getOrganizationWagerSet,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: selectedOrganization !== null,
      onSuccess: (data) => {
        setOrganizationWagerSet(data);
      },
    }
  );

  const { refetch: refetchCurrency } = useQuery(
    [
      "organizations",
      {
        integrator_id: selectedOrganization,
        provider_id: organizationId,
      },
    ],
    getOrganizationPair,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: organizationId !== null && selectedOrganization !== null,
      onSuccess: (data) => {
        setPairCurrency(data.id);
      },
      onError: (error) => {
        setError(error.response?.data?.data || "An unknown error occurred");
        if (error.response?.data?.data === "not found") {
          setHasError(false);
          setPairCurrency("");
        } else {
          setHasError(true);
        }
      },
    }
  );

  let { refetch: refetchIntegrators } = useQuery(
    ["integrators"],
    getOrganizations,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        setIntegrators(
          data.filter((organization) => organization.type === "integrator")
        );
        setProviders(data.filter((org) => org.type === "provider"));
        setOperators(data.filter((org) => org.type === "operator"));
      },
    }
  );

  const { refetch: refetchWagerSets } = useQuery(
    [
      "wager-set",
      {
        limit: 100,
        page: 1,
      },
    ],
    getWagerSets,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        const wagersArray = data?.items?.map((wager) => ({
          label: wager.name,
          value: wager.id,
        }));
        setWagerSets(wagersArray);
      },
    }
  );

  const { refetch: refetchGames, isRefetching } = useQuery(
    [
      "games-info",
      {
        selectedOrganization,
      },
    ],
    getOrganizationGames,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: selectedOrganization !== null,
      onSuccess: (data) => {
        setGames(data);
      },
    }
  );

  const { refetch: refetchPairOperator } = useQuery(
    [
      "games-info",
      {
        operator_id: selectedOperator,
        integrator_id: selectedOrganization,
      },
    ],
    getOperatorPair,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: selectedOrganization !== null && selectedOperator !== null,
      onSuccess: (data) => {
        setOperatorPair(data);
      },
      onError: (error) => {
        setError(error.response?.data?.data || "An unknown error occurred");
        if (error.response?.data?.data === "not found") {
          setHasError(false);
          setOperatorPair([]);
        } else {
          setHasError(true);
        }
      },
    }
  );
  const { refetch: refetchPair } = useQuery(
    [
      "games-info",
      {
        integrator_id: selectedOrganization,
        provider_id: selectedProvider,
      },
    ],
    getOrganizationPair,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: selectedOrganization !== null && selectedProvider !== null,
      onSuccess: (data) => {
        setPair(data);
        setPairWagerSet(data);
      },
      onError: (error) => {
        setError(error.response?.data?.data || "An unknown error occurred");
        if (error.response?.data?.data === "not found") {
          setHasError(false);
          setPair([]);
          setPairWagerSet(null);
        } else {
          setHasError(true);
        }
      },
    }
  );

  const { data: allGames, refetch: refetchAllGames } = useQuery(
    ["games"],
    getAllGames,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  const copyToastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success" ? `Copied successfully` : `Error: couldn't copy`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: type === "success" ? 2000 : 5000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const { mutate: removeIntegration } = useMutation(removeIntegratedGame, {
    onSuccess: () => {
      toastAlert("success");
      refetchGames();
      selectedItem && setSelectedItem(null);
    },
    onError: (error) => {
      setError(error.response.data.data);
      setHasError(true);
      selectedItem && setSelectedItem(null);
    },
  });

  const { mutate: removeWager } = useMutation(
    (formData) =>
      removeWagerSet({ queryKey: [{ selectedOrganization }] }, formData),
    {
      onSuccess: () => {
        toastAlert("success");
        refetchOrganizationWager();
        selectedItem && setSelectedItem(null);
      },
      onError: (error) => {
        setError(error.response.data.data);
        setHasError(true);
        selectedItem && setSelectedItem(null);
      },
    }
  );

  const { mutate: removeOperatorPair } = useMutation(deleteOperatorPair, {
    onSuccess: () => {
      toastAlert("success");
      refetchPair();
      setPair([]);
      selectedItem && setSelectedItem(null);
    },
    onError: (error) => {
      setError(error.response.data.data);
      setHasError(true);
      selectedItem && setSelectedItem(null);
      setPair([]);
    },
  });
  const { mutate: removePair } = useMutation(removeOrganizationPair, {
    onSuccess: () => {
      toastAlert("success");
      refetchPair();
      setPair([]);
      selectedItem && setSelectedItem(null);
    },
    onError: (error) => {
      setError(error.response.data.data);
      setHasError(true);
      selectedItem && setSelectedItem(null);
      setPair([]);
    },
  });

  const handleRemoveWagerSet = () => {
    console.log(selectedItem, "item");
    if (selectedItem) {
      let formData = {
        game_id: selectedItem.game_id,
        currency: selectedItem.currency,
        wager_set_id: selectedItem.wager_set_id,
      };

      removeWager(formData);
    }
  };

  const handleRemoveIntegration = () => {
    if (selectedItem) {
      let formData = {
        game_id: [],
      };
      formData.game_id.push(selectedItem.Game.id);
      // formData.game_id.push(selectedItem.GameID);
      formData.provider_id = selectedItem.Game.organization_id;
      formData.organizationId = selectedItem.organization_id;
      // formData.organizationId = selectedItem.OrganizationID;
      removeIntegration(formData);
    }
  };

  const handleEditButtonClick = (propertyValue) => {
    setSelectedEditItem(propertyValue);
    setEditIntegrationsModal(true);
  };
  const handleEditButtonClickWagerSet = (propertyValue) => {
    setSelectedEditItemWagerSet(propertyValue);
    setEditWagerSetModal(true);
  };

  const handleButtonClick = () => {
    const modifiedList = allGames.filter(
      (game) => !games.some((g) => g.GameID === game.id)
    );
    setFilteredGames(modifiedList);
    setIntegrateModal(true);
  };
  const handleButtonClickWagerSet = () => {
    setWagerSetModal(true);
  };

  const columns = [
    {
      className: "text-center",
      key: "Game",
      name: "Game ID",
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <span
            className="d-block text-truncate me-2"
            style={{
              maxWidth: 120,
            }}
          >
            {data?.id}
          </span>
          <button
            className="btn tx-10 p-1"
            onClick={() => copyToClipboard(data?.id, copyToastAlert)}
          >
            <i className="mdi mdi-content-copy"></i>
          </button>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "Game",
      name: "Name",
      render: (data) => (
        <div>
          <span
            className="d-block text-truncate mx-auto"
            style={{ maxWidth: 140 }}
          >
            {data?.name}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "Game",
      name: "Provider",
      render: (data) => (
        <div>
          <span
            className="d-block text-truncate mx-auto"
            style={{ maxWidth: 140 }}
          >
            {data?.organization_id}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "RTP",
      render: (data) => (
        <div>
          <span className="d-block text-truncate mx-auto">{data?.rtp}</span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Volatility",
      render: (data) => (
        <div>
          <span className="d-block text-truncate mx-auto">
            {data?.volatility}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Wager set",
      render: (data) => (
        <div>
          <span className="d-block text-truncate mx-auto">
            {/* {data.wager_set_id ? getWagerLabelById(data.wager_set_id) : ""} */}
            {data?.wager_set?.name && data?.wager_set?.name}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Short Link",
      render: (data) => (
        <div>
          <span className="d-block text-truncate mx-auto">
            {data?.short_link ? "true" : "false"}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Manage",
      width: 60,
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <button
            className="btn btn-outline-info d-flex align-items-center justify-content-center p-1 mg-r-10"
            onClick={() => handleEditButtonClick(data)}
          >
            <i className="fe fe-edit-3" />
          </button>
          <button
            className="btn btn-outline-danger d-flex align-items-center justify-content-center p-1"
            onClick={() => {
              setSelectedItem(data);
              setDeleteModal(true);
            }}
          >
            <i className="fe fe-trash" />
          </button>
        </div>
      ),
    },
  ];

  const wagerColumns = [
    {
      className: "text-center",
      key: "Game",
      name: "Game ID",
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <span
            className="d-block text-truncate me-2"
            style={{
              maxWidth: 120,
            }}
          >
            {data?.id}
          </span>
          <button
            className="btn tx-10 p-1"
            onClick={() => copyToClipboard(data?.id, copyToastAlert)}
          >
            <i className="mdi mdi-content-copy"></i>
          </button>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "Game",
      name: "Game name",
      render: (data) => (
        <div>
          <span
            className="d-block text-truncate mx-auto"
            style={{ maxWidth: 140 }}
          >
            {data?.name}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "Game",
      name: "Provider",
      render: (data) => (
        <div>
          <span
            className="d-block text-truncate mx-auto"
            style={{ maxWidth: 140 }}
          >
            {data?.organization_id}
          </span>
        </div>
      ),
    },
    // {
    //   className: "text-center",
    //   key: "all",
    //   name: "RTP",
    //   render: (data) => (
    //     <div>
    //       <span className="d-block text-truncate mx-auto">{data?.rtp}</span>
    //     </div>
    //   ),
    // },
    // {
    //   className: "text-center",
    //   key: "all",
    //   name: "Volatility",
    //   render: (data) => (
    //     <div>
    //       <span className="d-block text-truncate mx-auto">
    //         {data?.volatility}
    //       </span>
    //     </div>
    //   ),
    // },
    {
      className: "text-center",
      key: "all",
      name: "Currency",
      render: (data) => (
        <div>
          <span className="d-block text-truncate mx-auto">
            {data?.currency}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Wager set",
      render: (data) => (
        <div>
          <span className="d-block text-truncate mx-auto">
            {/* {data.wager_set_id ? getWagerLabelById(data.wager_set_id) : ""} */}
            {data?.wager_set?.name && data?.wager_set?.name}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Short Link",
      render: (data) => (
        <div>
          <span className="d-block text-truncate mx-auto">
            {data?.short_link ? "true" : "false"}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Manage",
      width: 60,
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <button
            className="btn btn-outline-info d-flex align-items-center justify-content-center p-1 mg-r-10"
            onClick={() => handleEditButtonClickWagerSet(data)}
          >
            <i className="fe fe-edit-3" />
          </button>
          <button
            className="btn btn-outline-danger d-flex align-items-center justify-content-center p-1"
            onClick={() => {
              setSelectedItem(data);
              setDeleteWagerSetModal(true);
            }}
          >
            <i className="fe fe-trash" />
          </button>
        </div>
      ),
    },
  ];

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Integration was modified successfully`
          : `Error: ${error}`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: type === "success" ? 2000 : 5000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const getWagerLabelById = (id) => {
    const wagerSet = wagerSets.find((wager) => wager.value === id);
    return wagerSet ? wagerSet.label : "";
  };

  useEffect(() => {
    refetchWagerSets();
  }, []);

  useEffect(() => {
    refetchIntegrators();
    refetchAllGames();
    if (selectedOrganization !== null) {
      refetchOrganizationWager();
      refetchGames();
    }
  }, [selectedOrganization, refetchGames, refetchOrganizationWager]);

  useEffect(() => {
    if (selectedOrganization !== null && selectedProvider !== null) {
      refetchPair();
    }
  }, [selectedOrganization, selectedProvider, refetchPair]);

  useEffect(() => {
    if (selectedOrganization !== null && selectedOperator !== null) {
      refetchPairOperator();
    }
  }, [selectedOrganization, selectedOperator, refetchPairOperator]);

  useEffect(() => {
    if (hasError) {
      toastAlert("error");
      setHasError(false);
    }
  }, [error, hasError]);

  return (
    <div className="games-page">
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Integrations
          </span>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      {/* <!-- row --> */}
      <Row className="overflow-hidden wagers-sets">
        <Col xl={11}>
          <Card className="mg-l-35 mg-t-35">
            <Card.Body>
              <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                <div className="bg-gray-100 nav-bg">
                  <Nav className="nav nav-tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="link-29">Games</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="link-30">
                        Provider/Integrator pair
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="link-31">
                        Operator/Integrator pair
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="link-32">Games wager sets</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="link-29">
                    <div className="d-flex align-items-center justify-content-between mg-t-15">
                      <div className="wd-300 d-flex justify-content-between align-items-center">
                        <label className="mg-t-7">Integrator</label>
                        <Select
                          className="form-control wd-200"
                          optionFilterProp="children"
                          placeholder={"Select integrator..."}
                          value={selectedOrganization}
                          onChange={(org) => setSelectedOrganization(org)}
                          options={
                            integrators?.map((item) => ({
                              value: item?.id,
                              label: item?.name,
                            })) || []
                          }
                        />
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip className="tooltip tooltip-primary1">
                              Choose integrator to see integrated games
                            </Tooltip>
                          }
                        >
                          <i className="fe fe-info cursor-pointer text-muted"></i>
                        </OverlayTrigger>
                      </div>
                      <div
                        onClick={() => {
                          handleButtonClick();
                        }}
                        className="cursor-pointer"
                      >
                        <span className="tx-15">Add new</span>
                        <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
                      </div>
                    </div>
                    <div className="pt-4 example1-table">
                      <Table
                        loading={isRefetching}
                        data={games ? games : []}
                        columns={columns}
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
                <Tab.Content>
                  <Tab.Pane eventKey="link-30">
                    <div className="d-flex align-items-center justify-content-between mg-t-25">
                      <div className="wd-45p d-flex align-items-center justify-content-between">
                        <div className="wd-200">
                          <label>Integrator</label>
                          <Select
                            className="form-control"
                            optionFilterProp="children"
                            placeholder={"Select integrator..."}
                            value={selectedOrganization}
                            onChange={(org) => setSelectedOrganization(org)}
                            options={
                              integrators?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                              })) || []
                            }
                          />
                        </div>
                        <div className="wd-200">
                          <label>Provider</label>
                          <Select
                            className="form-control"
                            optionFilterProp="children"
                            placeholder={"Select provider..."}
                            value={selectedProvider}
                            onChange={(org) => setSelectedProvider(org)}
                            options={
                              providers?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                              })) || []
                            }
                          />
                        </div>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip className="tooltip tooltip-primary1">
                              Choose integrator and provider to check if pair
                              exists
                            </Tooltip>
                          }
                        >
                          <i className="fe fe-info cursor-pointer text-muted mg-t-25"></i>
                        </OverlayTrigger>
                      </div>
                      <div
                        onClick={() => {
                          setAddPairModal(true);
                        }}
                        className="cursor-pointer mg-t-15"
                      >
                        <span className="tx-15">Add new</span>
                        <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
                      </div>
                    </div>
                    <div className="pt-5" style={{ minHeight: "80px" }}>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="tx-18 mg-b-0">
                          ID: {pair?.id ? pair.id : "No pair available"}
                        </p>
                        {pair?.id ? (
                          <div className="d-flex align-items-center justify-content-between wd-80">
                            <button
                              className="btn btn-outline-primary d-flex align-items-center justify-content-center p-2"
                              onClick={() =>
                                copyToClipboard(pair?.id, copyToastAlert)
                              }
                            >
                              <i className="fe fe-copy"></i>
                            </button>
                            <button
                              className="btn btn-outline-danger d-flex align-items-center justify-content-center p-2"
                              onClick={() => {
                                setSelectedItem(pair);
                                setDeletePairModal(true);
                              }}
                            >
                              <i className="fe fe-trash" />
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
                <Tab.Content>
                  <Tab.Pane eventKey="link-31">
                    <div className="d-flex align-items-center justify-content-between mg-t-25">
                      <div className="wd-45p d-flex align-items-center justify-content-between">
                        <div className="wd-200">
                          <label>Operator</label>
                          <Select
                            className="form-control"
                            optionFilterProp="children"
                            placeholder={"Select operator..."}
                            value={selectedOperator}
                            onChange={(operator) =>
                              setSelectedOperator(operator)
                            }
                            options={
                              operators?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                              })) || []
                            }
                          />
                        </div>
                        <div className="wd-200">
                          <label>Integrator</label>
                          <Select
                            className="form-control"
                            optionFilterProp="children"
                            placeholder={"Select integrator..."}
                            value={selectedOrganization}
                            onChange={(org) => setSelectedOrganization(org)}
                            options={
                              integrators?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                              })) || []
                            }
                          />
                        </div>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip className="tooltip tooltip-primary1">
                              Choose operator and integrator to check if pair
                              exists
                            </Tooltip>
                          }
                        >
                          <i className="fe fe-info cursor-pointer text-muted mg-t-25"></i>
                        </OverlayTrigger>
                      </div>
                      <div
                        onClick={() => {
                          setAddOperatorPairModal(true);
                        }}
                        className="cursor-pointer mg-t-15"
                      >
                        <span className="tx-15">Add new</span>
                        <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
                      </div>
                    </div>
                    <div className="pt-5" style={{ minHeight: "80px" }}>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="tx-18 mg-b-0">
                          ID:{" "}
                          {operatorPair?.id
                            ? operatorPair.id
                            : "No pair available"}
                        </p>
                        {operatorPair?.id ? (
                          <div className="d-flex align-items-center justify-content-between wd-80">
                            <button
                              className="btn btn-outline-primary d-flex align-items-center justify-content-center p-2"
                              onClick={() =>
                                copyToClipboard(
                                  operatorPair?.id,
                                  copyToastAlert
                                )
                              }
                            >
                              <i className="fe fe-copy"></i>
                            </button>
                            <button
                              className="btn btn-outline-danger d-flex align-items-center justify-content-center p-2"
                              onClick={() => {
                                setSelectedItem(operatorPair);
                                setDeletePairModal(true);
                              }}
                            >
                              <i className="fe fe-trash" />
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
                <Tab.Content>
                  <Tab.Pane eventKey="link-32">
                    <div className="d-flex align-items-center justify-content-between mg-t-15">
                      <div className="wd-300 d-flex justify-content-between align-items-center">
                        <label className="mg-t-7">Integrator</label>
                        <Select
                          className="form-control wd-200"
                          optionFilterProp="children"
                          placeholder={"Select integrator..."}
                          value={selectedOrganization}
                          onChange={(org) => setSelectedOrganization(org)}
                          options={
                            integrators?.map((item) => ({
                              value: item?.id,
                              label: item?.name,
                            })) || []
                          }
                        />
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip className="tooltip tooltip-primary1">
                              Choose integrator to see integrated games
                            </Tooltip>
                          }
                        >
                          <i className="fe fe-info cursor-pointer text-muted"></i>
                        </OverlayTrigger>
                      </div>
                      <div
                        onClick={() => {
                          handleButtonClickWagerSet();
                        }}
                        className="cursor-pointer"
                      >
                        <span className="tx-15">Add new</span>
                        <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
                      </div>
                    </div>
                    <div className="pt-4 example1-table">
                      <Table
                        loading={isWagerSetRefetching}
                        data={
                          organizationWagerSet ? organizationWagerSet : null
                        }
                        columns={wagerColumns}
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {integrateModal && (
        <IntegrateModal
          onSettled={refetchGames}
          setShowModal={setIntegrateModal}
          integrators={integrators}
          games={filteredGames}
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          error={error}
          setHasError={setHasError}
          setPair={setPair}
          setError={setError}
          allGames={allGames}
        />
      )}

      {wagerSetModal && (
        <WagerSetModal
          onSettled={refetchOrganizationWager}
          setShowModal={setWagerSetModal}
          integrators={integrators}
          games={filteredGames}
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          error={error}
          setHasError={setHasError}
          pairCurrency={pairCurrency}
          setError={setError}
          allGames={allGames}
          pairWagerSet={pairWagerSet}
        />
      )}

      {deleteModal && (
        <DeleteIntegrationModal
          setDeleteModal={setDeleteModal}
          onSubmit={() => handleRemoveIntegration()}
        />
      )}
      {deleteWagerSetModal && (
        <DeleteIntegrationModal
          setDeleteModal={setDeleteWagerSetModal}
          onSubmit={() => handleRemoveWagerSet()}
        />
      )}

      {addPairModal && (
        <AddPairModal
          type={"provider"}
          setShowModal={setAddPairModal}
          onSettled={refetchPair}
          integrators={integrators}
          providers={providers}
          error={error}
        />
      )}

      {addOperatorPairModal && (
        <AddPairModal
          type={"operator"}
          setShowModal={setAddOperatorPairModal}
          onSettled={refetchPairOperator}
          integrators={integrators}
          operators={operators}
          error={error}
        />
      )}

      {deletePairModal && (
        <DeletePairModal
          setDeleteModal={setDeletePairModal}
          onSubmit={() => {
            if (selectedItem?.operator_id) {
              removeOperatorPair(operatorPair);
              refetchPairOperator();
            } else if (selectedItem?.provider_id) {
              removePair(pair);
              refetchPair();
            }
          }}
        />
      )}

      {editIntegrationsModal && (
        <EditIntegrationModal
          setShowModal={setEditIntegrationsModal}
          selectedEditItem={selectedEditItem}
          refetchGames={refetchGames}
        />
      )}

      {editWagerSetModal && (
        <EditWagerSetModal
          setShowModal={setEditWagerSetModal}
          selectedEditItemWagerSet={selectedEditItemWagerSet}
          refetchGames={refetchOrganizationWager}
          allGames={allGames}
          pairCurrency={pairCurrency}
          wagerSets={wagerSets}
          selectedOrganization={selectedOrganization}
          refetchOrganizationWager={refetchOrganizationWager}
        />
      )}
    </div>
  );
};

export default Integrations;
