import httpClient from "./httpClient";

export const getSessionsList = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(
    `/reports/sessions?${params.toString()}`
  );
  if (data) {
    return data.data;
  }
};

export const getSession = async ({ queryKey }) => {
  const { id } = queryKey[1];
  const params = new URLSearchParams(queryKey[2]);
  const { data } = await httpClient.get(
    `/reports/sessions/${id}?${params.toString()}`
  );
  if (data) {
    return data.data;
  }
};

export const getPublicSession = async ({ queryKey }) => {
  const { id } = queryKey[1];
  const params = new URLSearchParams(queryKey[2]);
  try {
    const { data } = await httpClient.getPublic(
      `/public_reports/sessions/${id}?${params.toString()}`
    );
    if (data) {
      return data.data;
    }
  } catch (error) {
    console.error("Error fetching public session:", error);
    throw error;
  }
};
export const getPublicGameRound = async ({ queryKey }) => {
  const { round_id } = queryKey[1];

  try {
    const { data } = await httpClient.getPublic(
      `/public_reports/spins/${round_id}`
    );
    if (data) {
      return data.data;
    }
  } catch (error) {
    console.error("Error fetching public session:", error);
    throw error;
  }
};

export const getSessionsCsv = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(
    `/reports/sessions/xlsx?${params.toString()}`
  );
  if (data) {
    return data.data;
  }
};
