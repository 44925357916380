function rotateMatrixCounterClockwise(matrix) {
  const numRows = matrix.length;
  const numCols = matrix[0].length;
  let rotatedMatrix = [];

  for (let col = 0; col < numCols; col++) {
    let newRow = [];
    for (let row = 0; row < numRows; row++) {
      newRow.push(matrix[row][col]);
    }
    rotatedMatrix.unshift(newRow);
  }
  return rotatedMatrix;
}

export const stages = (gameName, data) => {
  let gameStages = [];

  const sameStructuredGames = [
    "smashing-hot",
    "smashing-hot-20",
    "smashing-hot-94",
    "smashing-hot-96",
    "smashing-hot-20-94",
    "smashing-hot-20-96",
    "burning-20-wins",
    "blazing-clovers",
    "green-gold",
    "book-of-dynasty",
    "book-of-sacred",
    "wild-west-john",
    "majestic-crown",
    "crystal-ways",

    "horror-depths-respin",
    "quest-of-ra",
    "stellar-wilds",
    "vampire-vault-hold-n-win",
    "wild-dragon-respin",
    "yakuza-clash-hold-n-win",
    "rio-bay-hold-n-win",
  ];

  if (
    gameName === "stones-of-magic" ||
    gameName === "secret-totems" ||
    gameName === "olympus-quest"
  ) {
    gameStages = data?.spin?.spins?.[0]?.stages;
  }

  if (
    gameName === "asgard-party" ||
    gameName === "ego-draconis" ||
    gameName === "gonzo-quest" ||
    gameName === "sweet-mystery-flexiways"
  ) {
    gameStages = data?.spin?.spins?.[0]?.stages;
  }

  if (gameName === "toucan-fruits") {
    gameStages = data?.spin?.avalanches.map((avalanche) => ({
      payouts: {
        values: avalanche.pay_items?.map((value) => ({
          amount: value.award,
          indexes: value?.indexes?.map((subIndexes) =>
            subIndexes?.map((index) => value?.indexes?.length - 2 - index)
          ),
        })),
      },
      reel_window: avalanche.window,
      bonus_game:
        data?.spin?.bonus?.spins?.length > 0
          ? {
              spins: data?.spin?.bonus?.spins?.map((item) => ({
                stages: item?.avalanches?.map((avalanche) => ({
                  reel_window: avalanche?.window,
                  payouts: {
                    values: avalanche.pay_items?.map((value) => ({
                      amount: value.award,
                      indexes: value?.indexes?.map((subIndexes) =>
                        subIndexes?.map(
                          (index) => value?.indexes?.length - 2 - index
                        )
                      ),
                    })),
                  },
                })),
              })),
            }
          : null,
    }));
  }
  if (
    gameName === "lucky-skulls-bonanza" ||
    gameName === "rush-moji" ||
    gameName === "lucky-santa-bonanza" ||
    gameName === "irish-riches-bonanza" ||
    gameName === "jelly-joy-bonanza"
  ) {
    gameStages = data?.spin?.avalanches.map((avalanche) => ({
      payouts: {
        multiplier_mask: avalanche.multiplier_mask,
        values: avalanche.pay_items?.map((value, index) => ({
          amount: value.award,
          indexes: value?.indexes,
          payline: index,
          symbol: value.symbol,
          count: value.indexes.length,
        })),
      },
      reel_window: avalanche.window,
      reel_window_reverse: rotateMatrixCounterClockwise(avalanche.window),
      bonus_game:
        data?.spin?.bonus?.spins?.length > 0
          ? {
              spins: data?.spin?.bonus?.spins?.map((item) => ({
                stages: item?.avalanches?.map((avalanche) => ({
                  reel_window: avalanche?.window,
                  // reel_window_reverse: avalanche?.window.map((reel) => [...reel].reverse()),
                  reel_window_reverse: rotateMatrixCounterClockwise(
                    avalanche?.window
                  ),
                  payouts: {
                    values: avalanche.pay_items?.map((value, index) => ({
                      amount: value.award,
                      indexes: value?.indexes,
                      payline: index,
                      symbol: value.symbol,
                      count: value.indexes.length,
                    })),
                  },
                })),
              })),
            }
          : null,
    }));
  }

  if (
    gameName === "magic-moonlight" ||
    gameName === "cleos-riches-flexiways" ||
    gameName === "great-bear-multiways" ||
    gameName === "witch-of-fortune-multiways"
  ) {
    gameStages = data?.spin?.avalanches.map((avalanche, index) => ({
      payouts: {
        values: avalanche.pay_items?.map((value) => ({
          amount: value.award,
          indexes: value?.indexes,
        })),
      },
      reel_window: avalanche.window,
      bonus_game:
        index === 0 && data?.spin?.bonus?.spins?.length > 0
          ? {
              spins: data?.spin?.bonus?.spins?.map((item) => ({
                stages: item?.avalanches?.map((avalanche) => ({
                  reel_window: avalanche?.window,
                  payouts: {
                    values: avalanche.pay_items?.map((value) => ({
                      amount: value.award,
                      indexes: value?.indexes,
                    })),
                  },
                })),
              })),
            }
          : null,
    }));
  }
  if (gameName === "coral-reef-flexiways" || gameName === "cat-mansion") {
    gameStages = [
      {
        payouts: {
          values: data?.spin?.pay_items?.map((value) => ({
            amount: value?.award,
            award_with_multipliers: value?.award_with_multipliers,
            indexes: value?.indexes,
            multipliers: value?.multipliers,
            symbol: value?.symbol,
          })),
        },
        multipliers: data?.spin.multipliers,
        reel_window: data?.spin?.window,
        bonus_game:
          data?.spin?.bonus_game?.spins?.length > 0
            ? {
                spins: data?.spin?.bonus_game?.spins?.map((item) => ({
                  stages: [
                    {
                      amount: item?.award,
                      multipliers: item?.multipliers,
                      reel_window: item?.window,
                      payouts: { values: item?.pay_items },
                    },
                  ],
                })),
              }
            : null,
      },
    ];
  }
  if (gameName === "viking-saga-flexiways") {
    gameStages = data?.spin?.avalanches.map((avalanche, index) => ({
      payouts: {
        values: avalanche.pay_items?.map((value) => ({
          amount: value.award,
          award_with_multiplier: value.award_with_multiplier,
          indexes: value?.indexes,
          symbol: value?.symbol,
        })),
      },
      reel_multiplier: 1,
      reel_window: avalanche.window,
      bonus_game:
        data?.spin?.bonus?.spins?.length > 0
          ? {
              spins: data?.spin?.bonus?.spins?.map((item) => ({
                stages: item?.avalanches?.map((avalanche) => ({
                  reel_window: avalanche?.window,
                  payouts: {
                    values: avalanche.pay_items?.map((value) => ({
                      amount: value.award,
                      indexes: value?.indexes,
                      award_with_multiplier: value?.award_with_multiplier,
                      symbol: value?.symbol,
                    })),
                  },
                  reel_multiplier: 1,
                })),
              })),
            }
          : null,
    }));
  }

  if (gameName === "aviator") {
    gameStages = data?.spin;
  }

  if (
    gameName === "precious-bugs" ||
    gameName === "cyber-town" ||
    gameName === "toreadors-fortune"
  ) {
    gameStages = [
      {
        payouts: {
          values: data?.spin?.payouts?.spin_payouts?.map((value) => ({
            amount: value.amount,
            payline: value.payline,
            count: value.count,
          })),
        },
        reel_window: data?.spin?.window,
        bonus_game:
          data?.spin?.bonus?.spins_results?.length > 0
            ? {
                spins: data?.spin?.bonus?.spins_results?.map((item) => ({
                  stages: [
                    {
                      reel_window: item?.window,
                      payouts: { values: item?.payouts?.spin_payouts },
                      stops: item?.reels?.stops,
                    },
                  ],
                })),
              }
            : null,
      },
    ];
  }

  if (
    gameName === "lucky-pint" ||
    gameName === "undead-riches" ||
    gameName === "retro-royale" ||
    gameName === "egyptians-book"
  ) {
    gameStages = [
      {
        payouts: {
          values: data?.spin?.pay_lines_to_show?.map((value) => ({
            amount: value.award,
            payline: value.index,
            count: value.indexes.length,
            indexes: value.indexes,
            symbol: value.symbol,
          })),
        },
        reel_multiplier: data?.spin?.wheel_multiplier || 1,
        reel_window: data?.spin?.window,
        bonus_game:
          data?.spin?.bonus?.spins?.length > 0
            ? {
                spins: data?.spin?.bonus?.spins?.map((item) => ({
                  stages: [
                    {
                      reel_window: item?.window,
                      payouts:
                        item.pay_lines_to_show?.length > 0
                          ? {
                              scatter_values: item.extra_scatter_pay_lines,
                              values: item.pay_lines_to_show?.map((value) => ({
                                amount: value.award,
                                payline: value.index,
                                count: value.indexes.length,
                                indexes: value.indexes,
                              })),
                            }
                          : {
                              values: null,
                              scatter_values: item.extra_scatter_pay_lines,
                            },
                    },
                  ],
                })),
              }
            : null,
      },
    ];
  }

  if (gameName === "book-of-jones-96" || gameName === "book-of-jones-94") {
    gameStages = [
      {
        payouts: {
          values: data?.spin?.payouts?.spin_payouts?.map((value) => ({
            amount: value.amount,
            payline: value.payline,
            count: value.count,
          })),
        },
        reel_multiplier: data?.spin?.wheel_multiplier || 1,
        reel_window: data?.spin?.window,
        bonus_game:
          data?.spin?.bonus_spins?.length > 0
            ? {
                spins: data?.spin?.bonus_spins?.map((item) => ({
                  stages: [
                    {
                      reel_window: item?.window,
                      reels: {
                        scatter_position:
                          item?.reels?.expanding_symbol_position,
                      },
                      payouts:
                        item.payouts_info?.spin_payouts?.length > 0
                          ? {
                              scatter_values:
                                item.payouts_info?.expanded_symbol_payouts ||
                                null,
                              values: item.payouts_info?.spin_payouts?.map(
                                (value) => ({
                                  amount: value.amount,
                                  payline: value.payline,
                                  count: value.count,
                                })
                              ),
                            }
                          : {
                              values: null,
                              scatter_values:
                                item.payouts_info?.expanded_symbol_payouts,
                            },
                      stops: item?.reels?.stops,
                    },
                  ],
                })),
              }
            : null,
      },
    ];
  }

  if (
    gameName === "carnival-beauty-respin" ||
    gameName === "fortune-777-respin"
  ) {
    gameStages = [
      {
        payouts: {
          values: data?.spin?.pay_items?.map((value) => ({
            amount: value?.award,
            amount_with_multiplier: value?.award_with_multiplier,
            indexes: value.indexes ? value?.indexes : value?.pay_line,
            multiplier: value?.multiplier,
            symbol: value.symbol,
            count: value?.indexes?.length
              ? value?.indexes.length
              : value?.pay_line.length,
          })),
        },
        reel_window: data?.spin?.window,
        reel_multiplier: data?.spin?.wheel_multiplier || 1,
        respin_game: data?.spin?.respin_game
          ? data?.spin?.respin_game
          : {
              spins: [...(data?.spins || []), data?.spin?.re_spin],
            },
      },
    ];

    if (data?.spin?.bonus_game) {
      gameStages = [
        {
          ...gameStages,
          // ...gameStages[0],
          bonus_game: {
            spins: [
              ...(data?.spin?.bonus_game?.spins
                ? data?.spin?.bonus_game?.spins
                : data?.spin?.bonus_game),
            ].map((spin) => ({
              stages: [
                {
                  reel_window:
                    spin?.is_expended_wild?.length > 0 ||
                    spin?.reels_with_crown?.length > 0
                      ? spin?.window?.map((item, index) => {
                          if (gameName === "green-gold" && item?.includes("w"))
                            return ["w", "w", "w", "w"];
                          const reelsWithCrownIndexes =
                            spin?.reels_with_crown ?? [];
                          if (
                            reelsWithCrownIndexes.length > 0 &&
                            reelsWithCrownIndexes.includes(index)
                          ) {
                            return ["w", "w", "w"];
                          }
                          return item;
                        })
                      : spin?.window,
                  payouts:
                    spin.pay_items?.length > 0
                      ? {
                          // scatter_values: spin.extra_scatter_pay_lines,
                          values: spin.pay_items?.map((value) => ({
                            amount: value.award,
                            amount_with_multiplier: value.award_with_multiplier,
                            indexes: value.indexes,
                            multiplier: value.multiplier,
                            symbol: value.symbol,
                            count: value.indexes.length,
                          })),
                        }
                      : {
                          values: null,
                          // scatter_values: spin.extra_scatter_pay_lines,
                        },
                  stops: spin?.stops,
                },
              ],
            })),
          },
        },
      ];
    }
  }

  if (
    sameStructuredGames.some((item) => gameName?.toLowerCase()?.includes(item))
  ) {
    gameStages = [
      {
        payouts: {
          values: data?.spin?.pay_lines_to_show?.map((value) => ({
            amount: value.award,
            payline: value.index,
            count: value.indexes.length,
            indexes: value.indexes,
            direction: value.direction,
          })),
        },
        reel_window:
          data?.spin?.is_expended_wild?.length > 0 ||
          data?.spin?.reels_with_crown?.length > 0
            ? data?.spin?.window?.map((item) => {
                if (gameName === "green-gold" && item?.includes("w"))
                  return ["w", "w", "w", "w"];
                if (
                  gameName.includes("blazing-clovers-40") &&
                  item?.includes("w")
                )
                  return ["w", "w", "w", "w"];
                if (item?.includes("w")) return ["w", "w", "w"];
                return item;
              })
            : data?.spin?.window,
        reel_type: "reel_type" in data?.spin ? data?.spin.reel_type : null,
      },
    ];

    if (data?.spin?.bonus) {
      gameStages = [
        {
          ...gameStages[0],
          bonus_game: {
            spins: [
              ...(data?.spin?.bonus?.spins
                ? data?.spin?.bonus?.spins
                : data?.spin?.bonus),
            ].map((spin) => ({
              stages: [
                {
                  reel_window:
                    spin?.is_expended_wild?.length > 0 ||
                    spin?.reels_with_crown?.length > 0
                      ? spin?.window?.map((item, index) => {
                          if (gameName === "green-gold" && item?.includes("w"))
                            return ["w", "w", "w", "w"];
                          const reelsWithCrownIndexes =
                            spin?.reels_with_crown ?? [];
                          if (
                            reelsWithCrownIndexes.length > 0 &&
                            reelsWithCrownIndexes.includes(index)
                          ) {
                            return ["w", "w", "w"];
                          }
                          return item;
                        })
                      : spin?.window,
                  payouts:
                    spin.pay_lines_to_show?.length > 0
                      ? {
                          scatter_values: spin.extra_scatter_pay_lines,
                          values: spin.pay_lines_to_show?.map((value) => ({
                            amount: value.award,
                            payline: value.index,
                            count: value.indexes.length,
                            indexes: value.indexes,
                          })),
                        }
                      : {
                          values: null,
                          scatter_values: spin.extra_scatter_pay_lines,
                        },
                  stops: spin?.stops,
                },
              ],
            })),
          },
        },
      ];
    }
    if (data?.spin?.free_game) {
      gameStages = [
        {
          ...gameStages[0],
          bonus_game: {
            spins: [
              ...(data?.spin?.free_game?.free_spins
                ? data?.spin?.free_game?.free_spins
                : data?.spin?.bonus),
            ].map((spin) => ({
              stages: [
                {
                  reel_window: spin?.window,
                  payouts:
                    spin.pay_lines_to_show?.length > 0
                      ? {
                          values: spin.pay_lines_to_show?.map(
                            (value, index) => ({
                              amount: value.award,
                              indexes: value.indexes,
                              symbol: value.symbol,
                              payline: value.index ? value.index : index,
                              count: value.indexes.length,
                            })
                          ),
                        }
                      : {
                          values: null,
                        },
                  stops: spin?.stops,
                },
              ],
            })),
          },
        },
      ];
    }
    if (
      gameName === "vampire-vault-hold-n-win" ||
      gameName === "wild-dragon-respin" ||
      (gameName === "yakuza-clash-hold-n-win" && data?.spin?.bonus_game)
    ) {
      gameStages = [
        {
          ...gameStages[0],
          bonus_multiplier_game: {
            award: data?.spin?.bonus_game?.award,
            window:
              data?.spin?.bonus_game?.spins[
                data?.spin?.bonus_game?.spins.length - 1
              ]?.window,
          },
        },
      ];
    }
  }

  if (gameName === "admiral-wilds") {
    gameStages = [
      {
        payouts: {
          values: data?.spin?.pay_lines?.map((value) => ({
            amount: value?.award,
            indexes: value?.indexes,
            payline: value?.pay_line_index,
            symbol: value?.symbol,
            count: value.indexes.length,
          })),
        },
        reel_window: data?.spin?.window,
      },
    ];

    if (data?.spin?.free_spins) {
      const allFreeSpins = [
        ...(data?.spin?.free_spins?.first_stage?.free_spins || []),
        ...(data?.spin?.free_spins?.second_stage?.free_spins || []),
      ];

      gameStages = [
        {
          ...gameStages[0],
          bonus_game: {
            spins: allFreeSpins.map((spin) => ({
              stages: [
                {
                  reel_window: spin?.window,
                  payouts:
                    spin.pay_lines?.length > 0
                      ? {
                          values: spin.pay_lines?.map((value, index) => ({
                            amount: value.award,
                            indexes: value.indexes,
                            symbol: value.symbol,
                            payline:
                              value.pay_line_index !== undefined
                                ? value.pay_line_index
                                : index,
                            count: value.indexes.length,
                          })),
                        }
                      : {
                          values: null,
                        },
                  stops: spin?.stops,
                },
              ],
            })),
          },
        },
      ];
    }
  }
  return gameStages;
};
