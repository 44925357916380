import React, { forwardRef } from "react";
import { Carousel } from "react-bootstrap";
import { symbolImageLink } from "../../helpers/symbols/symbolImageLink";
import "./WinlinesCarouselAssymetric.css";

const WinlinesCarouselAssymetric = forwardRef(({ stage, gameName }, ref) => {
  const renderSpinMatrixForWinline = (payout, key) => {
    const { reel_window, multipliers } = stage;
    let multiplierIndex = 0;

    const payoutsMultipliers = stage.payouts.values[key].multipliers;

    const renderSymbol = (symbol) => {
      if (symbol === 12) {
        const multiplier = multipliers
          ? multipliers[multiplierIndex]
          : payoutsMultipliers[multiplierIndex];
        const displayText = multiplier === 1 ? "WILD" : `X${multiplier}`;
        const style = multiplier === 1 ? { fontSize: 34 } : null;
        if (multipliers) {
          if (multiplierIndex < multipliers.length - 1) {
            multiplierIndex++;
          }
        } else if (payoutsMultipliers) {
          if (multiplierIndex < payoutsMultipliers.length - 1) {
            multiplierIndex++;
          }
        }

        return (
          <span style={style} className="winNumber">
            {displayText}
          </span>
        );
      }
      return null;
    };

    const tableRows = (
      <div className="table-div">
        <div className="columns-container">
          {reel_window?.map((column, columnKey) => (
            <div className="column-container" key={columnKey}>
              {column.map((symbol, rowIndex) => {
                const isSymbolInWinLine =
                  payout.indexes[columnKey] === rowIndex;

                let size;
                switch (column.length) {
                  case 2:
                    size = 1;
                    break;
                  case 3:
                    size = 2;
                    break;
                  case 4:
                    size = 3;
                    break;
                  case 5:
                    size = 4;
                    break;
                  case 6:
                    size = 5;
                    break;
                  default:
                    size = 6;
                }

                const brightness = isSymbolInWinLine ? 1 : 0.4;

                return (
                  <div
                    className={`${rowIndex} symbol-container symbol-size-${size}`}
                    key={`${rowIndex}-${columnKey}`}
                    style={{
                      display: "flex",
                      justifyContent:
                        multipliers || payoutsMultipliers
                          ? multipliers ||
                            payoutsMultipliers[multiplierIndex] === 1
                            ? "flex-start"
                            : "center"
                          : null,
                      alignItems: "center",
                      filter: `brightness(${brightness})`,
                      backgroundImage: symbol
                        ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                            gameName
                          )}/${String(symbol).toLowerCase()}_${size}.png)`
                        : "none",
                    }}
                  >
                    {(multipliers || payoutsMultipliers) && symbol
                      ? renderSymbol(symbol)
                      : null}
                    {!symbolImageLink(gameName) &&
                      (symbol ? String(symbol).toUpperCase() : "")}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );

    return (
      <table className={`game-stats-info__stats-class mt-0`} id="symbols_table">
        <thead>
          <tr className="text-center">
            <td colSpan={5} className="py-3">
              Table - {key + 1}
            </td>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    );
  };

  const renderWinResultsTable = (activeKey) => {
    const activePayout = stage.payouts?.values[activeKey];
    return (
      <table
        className="game-stats-info__stats-class mt-0"
        id="win_results_table"
      >
        <thead>
          <tr className="text-center">
            <td colSpan={3} className="py-3">
              Winning result
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <th>Payline</th>
            <th>Award</th>
            <th>Symbol</th>
          </tr>
          {activePayout && (
            <tr className="text-center">
              <td>
                {activePayout.payline
                  ? activePayout.payline + 1
                  : activeKey + 1}
              </td>
              <td>{activePayout.award_with_multipliers / 1000}</td>
              <td>{activePayout.amount / 1000}</td>
              <td>{activePayout.symbol}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <Carousel interval={null} controls={stage.payouts?.values?.length > 1}>
      {stage.payouts?.values?.map((payout, key) => (
        <Carousel.Item key={key}>
          <div className="win-matrix-container">
            {renderSpinMatrixForWinline(payout, key)}
          </div>
          {renderWinResultsTable(key)}
        </Carousel.Item>
      ))}
    </Carousel>
  );
});

export default WinlinesCarouselAssymetric;
